.blog {
  .post {
    border: 3px solid #f5f8fa;
    border-radius: 3px;
    margin-bottom: 30px;
    padding: 25px;
  }
  .ico {
    position: absolute;
    top: 50px;
    font-size: 40px;
    color: #E1E8EE;

  }
  h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    padding-bottom: 10px;
    padding-top: 100px;
  }
  h2 a {
    color: #43484d;
  }
  .pagination > li > a,
  .pagination > li > span {
    color: #03a9f4;
  }
}