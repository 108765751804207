.error-page .content-area {
  background: url("../img/icon/icon-stone.png") no-repeat scroll center center;
  background-color: #f5f8fa;
  header {
    padding: 30px 0 120px;
  }
  .message p {
    font-size: 180px;
    font-weight: 700;
    text-align: center;
  }
}