.mokupSlider-wrapper {
  position: relative;
  padding-bottom: 50px;
  padding-top: 50px;
  .laptop-mokup img {
    left: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    @include phone {
      display: none;
    }
  }

  .mokupSlider {
    text-align: center;
    .screenshot {
      display: block;
      left: -11px;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      max-width: 700px;
      padding: 1% !important;

      @include tablet {
        width: 75%;
        height: 370px;
      }
      @include phone {
        width: 75%;
        height: 270px;
      }
    }
  }
  .mokupSlider img {
    text-align: center;
  }
  .mokupSlider li img {
    position: relative;
    transition: top 4s ease-out 0s;
    -webkit-transition: top 4s ease-out 0s;
    -moz-transition: top 4s ease-out 0s;
    -o-transition: top 4s ease-out 0s;
  }
  .mokupSlider li a {
    height: 430px;
    overflow: hidden;
    position: relative;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .lSAction > a {
    width: 44px;
  }

}