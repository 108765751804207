.product-head {
  background-repeat: no-repeat;
  padding: 100px 0 !important;
  background-size: cover;
  z-index: -1;
  h1,
  p {
    color: #fff;
    padding-bottom: 10px;
  }
}

.product-wrapper {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  img {
    border-radius: 3px 3px 0 0;
    height: auto;
    max-width: 100%;
  }
  .category {
    background: skyblue none repeat scroll 0 0;
    color: #fff;
    font-size: 12px;
    padding: 3px 12px;
    position: absolute;
    right: 25px;
    top: 30px;
  }
  .content {
    padding: 10px 20px;
    position: relative;
  }
  .download {
    background: tomato none repeat scroll 0 0;
    border-radius: 100%;
    bottom: 57px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: 21px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    right: 35px;
    text-align: center;
    width: 40px;
  }
}

#woo-lightSlider {
  ul {
    list-style: none outside none;
    padding-left: 0;
    margin-bottom: 0;
  }
  li {
    display: block;
    float: left;
    margin-right: 6px;
    cursor: pointer;
  }
  img {
    display: block;
    height: auto;
    max-width: 100%;
  }
}

.product {
  h3 a {
    font-size: 14px;
    line-height: 1.5;
    padding-right: 25px;
    font-weight: 400;
    color: $default-text-color;
    text-decoration: none;
    &:hover {
      color: $brand-primary;
    }
  }
}

.product-wrapper .woocommerce-Price-amount.amount,
.product-wrapper .amount {
  background: #03a9f4 none repeat scroll 0 0;
  bottom: 60px;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 300;
  height: 50px;
  line-height: 50px;
  margin-bottom: 0;
  position: absolute;
  right: 30px;
  text-align: center;
  min-width: 50px;
  top: -25px;
  padding: 0 20px;
}

.p_meta {
  border-bottom: 1px solid #eee;
  background: #fafafa;
  li {
    list-style: none;
    display: inline-block;
    font-size: 12px;
    color: $light-text;
    position: relative;
    padding: 12px 30px;
    margin-left: 20px;
    span {
      position: absolute;
      left: 0;
      top: 6px;
    }
  }
}