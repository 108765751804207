//Portfolio
// --------------------------------------------------

.portfolio {
  .post-portfolio {
    margin-bottom: 15px;
  }

}

//.hover-content {
//    width: 100%;
//    height: 62px;
//    position: absolute;
//    left: -50%;
//    top: 50%;
//    margin-top: -31px;
//}

.portfolio h5 {
  float: left;
}

#project-wrapper, .project-sliders {
  margin-top: 65px;
}

.project {
  text-align: center;
  width: 370px;
  float: left;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
  figure img {
    width: 100%;
    height: auto;
  }
  //&:hover img {
  //    transform: rotate(10deg) scale(1.5);
  //    -webkit-transform: rotate(10deg) scale(1.5);
  //    -moz-transform: rotate(10deg) scale(1.5);
  //    -o-transform: rotate(10deg) scale(1.5);
  //    -ms-transform: rotate(10deg) scale(1.5);
  //    transition-duration: 0.5s;
  //}
  //.hover {
  //    width: 100%;
  //    height: 100%;
  //    background: #2bb7ff;
  //    background: rgba(43, 183, 288, 0.8);
  //    position: absolute;
  //    left: 100%;
  //    top: 0;
  //    opacity: 0;
  //    text-align: center;
  //}
}

.lt-ie9 .project .hover {
  display: none;
}

.project:hover .hover {
  opacity: 1;
  left: 0;
}

.lt-ie9 .project:hover .hover {
  display: block;
}

.project {
  &:hover .hover-content {
    left: 0;
  }
  .hover {
    span {
      color: #fff;
      font-size: 28px;
      border: 2px solid #fff;
      border-radius: 50%;
      height: 60px;
      width: 60px;
      line-height: 56px;
      margin-right: 2px;
      margin-left: 2px;
      &.livicon {
        display: inline-block;
        height: 60px !important;
        width: 60px !important;
        line-height: 70px;
        path {
          fill: #fff;
        }
      }
    }
    a {
      display: inline-block;
    }
  }
}

.project-sliders {
  position: relative;
}

.portfolio.style2 {
  text-align: center;
  padding-top: 85px;
  .top-nav {
    div {
      display: block;
      cursor: pointer;
      font-size: 30px;
      height: 65px;
      line-height: 65px;
      text-align: center;
      display: table;
      width: 65px;
      border: none;
      color: #fff;
      background: #000;
    }
    .portfolio-prev-style2 {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -33px;
      z-index: 9999;
    }
    .portfolio-next-style2 {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -33px;
      z-index: 9999;
    }
  }
  .project {
    width: 24.97%;
    margin: 0;
  }
  li {
    float: none;
  }
}

.project-filter {
  margin: 25px auto;
  li {
    display: inline-block;
    font-size: 16px;
    color: $default-text-color;
    text-transform: capitalize;
    cursor: pointer;
    &:after {
      content: '|';
      padding: 5px 10px;
      color: $brand-border;
    }
    &:last-child:after {
      content: '|';
      display: none;
    }

    &.active {
      color: $brand-primary !important;
    }
  }
}

.meta-tags li a {
  color: #666666;
}

.portfolio-detail {
  h1 {
    font-size: 28px;
  }
  .meta-attributes__table {
    font-size: 12px;
    line-height: 40.5px;
  }
  .meta-attributes__attr-name {
    width: 50%;
  }
  .folio-prev, .folio-next {
    height: 70px;
    width: 70px;
    line-height: 70px;
    color: #fff;
    font-size: 25px;
    background: #2bb7ff;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -35px;
    cursor: pointer;
  }
  .sidebar {
    margin-top: 0;
  }
  .folio-prev {
    left: -100px;
  }
  .folio-next {
    right: -100px;
  }
  h4 {
    padding-top: 15px;
  }
  figure {
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
  }
  li {
    font-size: 16px;
    color: #666666;
    display: inline-block;
    span {
      color: #000;
    }
  }
  .folio-info {
    position: relative;
    padding-bottom: 25px;
    margin-top: 22px;
    p {
      padding-bottom: 30px;
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

#project-wrapper1 {
  position: relative;
  h3 {
    padding-bottom: 28px;
  }
  &.style2 .project {
    width: 270px;
    float: none;
    margin: 0;
    margin-bottom: 24px;
  }
}

.border-blue {
  width: 70px;
  height: 3px;
  background: #2bb7ff;
  margin-bottom: 20px;
  margin-top: 25px;
}