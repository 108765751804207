.masonry-container, .paper-items {
  &.masonry-post-margin {
    .masonry-post {
      margin-bottom: 20px;
      .lightSlider, .lSSlide {
        width: 400px !important;
      }
    }
  }
  figure {
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04), 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 20px;
    &.figure {
      box-shadow: none;
      margin: 0;
    }
    .img-wrapper {
      position: relative;
      overflow: hidden;
    }

    img {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
      -webkit-transition: -webkit-transform 400ms;
      transition: transform 400ms;
    }
    &:hover {
      img {
        -webkit-transform: scale3d(1.2, 1.2, 1);
        transform: scale3d(1.2, 1.2, 1);
      }
      .img-overlay {
        opacity: 1;
        .buttons a {
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
        }
      }
    }
    .img-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 10px;
      background: rgba(255, 255, 255, 0.9);
      opacity: 0;
      -webkit-transition: opacity 400ms;
      transition: opacity 400ms;

    }
    .buttons {
      text-align: center;
      position: absolute;
      top: 45%;
      left: 0;
      right: 0;
      margin: 0 auto;

    }
    figcaption, .figcaption {
      padding: 20px 25px;
      margin-top: 0;
      color: $default-text-color;
      h4 {
        margin: 0;
      }
      p {
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 0;

      }
    }
    .figcaption {
      padding: 50px 25px;
      p {
        margin-bottom: 20px;
      }
    }
    &.style2 {
      box-shadow: none;
      figcaption {
        padding: 20px 0;
      }
    }
  }
}
