/*=====================
slider
=====================================*/

.xv-slider-wrap {
  background: #26374a;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  color: $default-text-color;
  .xv-slide {
    .container {
      position: relative;
    }
    background-position: center center;
    background-size: cover;
    position: relative;
    padding: 50px 0;
  }
  .smSlider .xv-slide {
    padding: 30px 0;
    height: 425px;
  }
  /*animations*/
  .lslide.active {
    .fadeInUp {
      animation-name: fadeInUp;
    }
    .fadeInDown {
      animation-name: fadeInDown;
    }
    .bounceIn {
      animation-name: bounceIn;
    }
    .bounceInRight {
      animation-name: bounceInRight;
    }
    .bounceInLeft {
      animation-name: bounceInLeft;
    }
    .bounceInUp {
      animation-name: bounceInUp;
    }
    .bounceInDown {
      animation-name: bounceInDown;
    }
    .fadeIn {
      animation-name: fadeIn;
    }
    .fadeInLeft {
      animation-name: fadeInLeft;
    }
    .fadeInRight {
      animation-name: fadeInRight;
    }
    .fadeInUpShort {
      animation-name: fadeInUpShort;
    }
    .fadeInLeftShort {
      animation-name: fadeInLeftShort;
    }
    .fadeInRightShort {
      animation-name: fadeInRightShort;
    }
    .fadeInDownShort {
      animation-name: fadeInDownShort;
    }
  }
}

.slidecaption {
  color: #fff;
  padding-left: 142px;
}

.xv-slider-wrap {
  .smSlider .xv-slide .slidecaption {
    position: absolute;
    bottom: 15%;
    left: 10%;
    width: 80%;
    padding: 0;
  }
  .xv-slide .slidecaption ul {
    margin-bottom: 20px;
    > li {
      display: block;
      font-size: 14px;
      padding-bottom: 3px;
      padding-top: 3px;
    }
  }
}

.xv-feature-wrap {
  position: relative;

  color: #fff;
  .xv-feature-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 48%;
    overflow: hidden;
    background: rgb(27, 26, 37);
    background: -moz-linear-gradient(left, rgba(27, 26, 37, 1) 0%, rgba(27, 27, 38, 1) 19%, rgba(27, 27, 38, 1) 19%, rgba(25, 36, 51, 1) 40%, rgba(19, 62, 87, 1) 67%, rgba(15, 81, 115, 1) 88%, rgba(16, 74, 108, 1) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(27, 26, 37, 1)), color-stop(19%, rgba(27, 27, 38, 1)), color-stop(19%, rgba(27, 27, 38, 1)), color-stop(40%, rgba(25, 36, 51, 1)), color-stop(67%, rgba(19, 62, 87, 1)), color-stop(88%, rgba(15, 81, 115, 1)), color-stop(100%, rgba(16, 74, 108, 1)));
    background: -webkit-linear-gradient(left, rgba(27, 26, 37, 1) 0%, rgba(27, 27, 38, 1) 19%, rgba(27, 27, 38, 1) 19%, rgba(25, 36, 51, 1) 40%, rgba(19, 62, 87, 1) 67%, rgba(15, 81, 115, 1) 88%, rgba(16, 74, 108, 1) 100%);
    background: -o-linear-gradient(left, rgba(27, 26, 37, 1) 0%, rgba(27, 27, 38, 1) 19%, rgba(27, 27, 38, 1) 19%, rgba(25, 36, 51, 1) 40%, rgba(19, 62, 87, 1) 67%, rgba(15, 81, 115, 1) 88%, rgba(16, 74, 108, 1) 100%);
    background: -ms-linear-gradient(left, rgba(27, 26, 37, 1) 0%, rgba(27, 27, 38, 1) 19%, rgba(27, 27, 38, 1) 19%, rgba(25, 36, 51, 1) 40%, rgba(19, 62, 87, 1) 67%, rgba(15, 81, 115, 1) 88%, rgba(16, 74, 108, 1) 100%);
    background: linear-gradient(to right, rgba(27, 26, 37, 1) 0%, rgba(27, 27, 38, 1) 19%, rgba(27, 27, 38, 1) 19%, rgba(25, 36, 51, 1) 40%, rgba(19, 62, 87, 1) 67%, rgba(15, 81, 115, 1) 88%, rgba(16, 74, 108, 1) 100%);
    // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1b1a25', endColorstr='#104a6c', GradientType=1);
  }
}

.xv-feature-item > img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  right: 0;
  height: auto;
}

.xv-feature-wrap .xv-feature-item:last-child {
  top: auto;
  bottom: 0;
}

.xv-feature-item {
  padding-left: 40px;
  overflow: hidden;
  span {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
  }
  .link {
    margin-top: 44px;
    color: #96aec8;
    padding: 4px;
    font-size: 12px;
    &:hover {
      color: #5fb7ea;
    }
  }
}

.feature-content {
  position: relative;
  z-index: 20;
}

.xv-slider-wrap.style {
  padding-top: 185px;
  padding-bottom: 130px;
}

.xv-slider-content {
  padding-top: 100px;
  padding-left: 20px;
  h1 {
    font-weight: 100;
    margin: 0;
    padding-bottom: 50px;
  }
  p {
    line-height: 1.7;
    font-size: 18px;
    font-weight: 100;
  }
  .btn-price {
    margin-left: 0 !important;
  }
  .btn-toolbar {
    margin-top: 50px;
  }
  .btn-img {
    padding: 0 15px 0 0;
    margin-bottom: 20px;
  }

  @include tablet-and-phone {
    text-align: center;
  }
}

.xv-slider-wrap {
  &.style1 {
    padding: 140px 0 130px;
  }
  &.style2 {
    padding: 100px 0 60px;
    span .fa {
      padding-top: 8px;
    }
    .btn-slider-prev {
      border-radius: 50%;
      color: #1b1d26;
      background: #8e8e93;
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 20px;
      height: 40px;
      width: 40px;
      text-align: center;
      font-size: 24px;
      margin-top: -20px;
      z-index: 999;
    }
    .btn-slider-next {
      border-radius: 50%;
      color: #1b1d26;
      background: #8e8e93;
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 20px;
      height: 40px;
      width: 40px;
      text-align: center;
      font-size: 24px;
      margin-top: -20px;
      z-index: 999;
    }
  }
}

.slider-container {
  position: relative;
  display: block;
}

.slider-container .slider-controls a {
  position: absolute;
  top: 45%;
  z-index: 100;
  color: #333333;
}

.slider-container .slider-controls a.slider-left {
  left: -25px;
}

.slider-container .slider-controls a.slider-right {
  right: -25px;
}

.thumbnail-slider-container {
  margin-top: 5px;
}

.thumbnail-slider .content {
  padding: 5px;
}

.thumbnail-slider .owl-item.active.center {
  border: 3px solid #333333;
}
