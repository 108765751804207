// colors
$main-text: #7f8c97; // main text
$light-text: #acb7c0; // anchor tags
$background: #e9f0f5; // body background color
$dark-heading-color: #606676;
$default-text-color: #86939e;
$brand-border2: #e1e8ee;

$white: #fff;
$color-1: #4ea9e0; // blue dark
$color-2: #5e6977; // red
$color-5: #f5f8fa; // border offwhtie
$color-6: #43484d;
$color-7: #eeeeee; //border colors
$color-9: #F6F8FA;
$color-10: #fff; // primary color hyerlinks
$color-11: #26374A;
$hero_bg: #f5f8fa;
// fonts 
$primary-font: 'Droid Serif',
serif;
$secondary-font: 'Open Sans',
sans-serif;
$font-weight-100: 100;
