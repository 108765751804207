.blog {
  .blog-default {
    article {
      text-align: center;
      margin-bottom: 100px;
    }
    .post-type {
      border: 3px solid #f5f8fa;
      border-radius: 50%;
      color: #a2acb4;
      display: inline-block;
      font-size: 18px;
      height: 50px;
      line-height: 45px;
      width: 50px;
    }
  }
}



.latest-posts {
  margin: 50px 0;
}

.blog-box {
  .post {
    border: 3px solid #f5f8fa;
    border-radius: 3px;
    margin-bottom: 30px;
    padding: 25px;
    h2 {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      padding-bottom: 10px;
      padding-top: 100px;
      a {
        color: $color-6;
      }
      a:hover {
        text-decoration: none;
        outline: 0 none;
        color: $brand-primary;
      }
    }
  }
  .icon-article {
    position: absolute;
    top: 50px;
  }
}

//Single Post-----------------------------------------------//
.content-wrapper {
  padding: 40px 0;
}

.post {
  .featured {
    img {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
      padding: 10px;
    }
  }
  .meta {
    margin: 20px 0 0;
    padding-bottom: 10px;
    p {
      padding: 15px 0;
    }
    li {
      display: inline-block;
      font-weight: 300;
      color: #86939e;
      i {
        padding-right: 5px;
      }

      span {
        color: #43484d;
      }
    }

    li::after {
      content: "|";
      padding: 0 20px;
    }
    li:last-child::after {
      content: none;
    }

  }
  img {
    max-width: 100%;
    height: auto;
  }
  blockquote {
    background: #082c46 none repeat scroll 0 0;
    border: medium none;
    border-radius: 5px;
    color: #fff;
    font-family: monospace;
    font-size: 12px;
    margin: 30px 0;
    padding: 30px 50px;
    line-height: 30px;
  }
}

.feedback {
  border-top: 1px solid #eee;
  margin-top: 50px;
  input[type="text"] {
    font-size: 18px;
    border-radius: 5px;
    border: 2px solid #e1e8ee;
    color: #43484d;
    height: 60px;
    width: 100%;
    margin: 20px 0;
    padding: 20px;

  }
  .count {
    padding-right: 15px;
    padding-top: 18px;
    vertical-align: middle;
  }
  .feedback-btn {
    display: inline-block;
    padding-left: 5px;
    padding-top: 20px;
    vertical-align: middle;

  }
}

.single-masthead {
  padding: 30px;
}

.single {
  .post {
    h1 {
      text-align: center;
    }
    .meta {
      text-align: center;
      margin: 20px;

      border-bottom: 1px solid $brand-border;

    }
    img {
      padding-bottom: 25px;
    }
    .excerpt {
      font-size: 20px;
      font-weight: 100;
      padding: 40px 0;
    }
  }
  .post-comments {
    background-color: $color-5;
    border-top: 1px solid $color-7;
    border-bottom: 1px solid $color-7;
    padding: 50px 0;
    margin-bottom: 50px;
    text-align: center;
    .btn {
      margin-top: 15px;
    }
  }
}

.mini {
  li {
    &:after {
      padding: 0 13px !important
    }
  }
}
