textarea {
  border: 1px solid $brand-border;
  border-radius: 3px;
  font-size: 14px;
  height: 50px;
  margin: 10px 0;
  padding: 15px;
  width: 100%;
  height: 100px;
}

.form-control {
  border: 1px solid $brand-border;
  box-shadow: none;
  border-radius: 3px;
  height: 40px;
  &:focus {
    box-shadow: none;
    border-color: #1BB1F5;
  }
}

.has-warning .form-control {
  border-color: orange;
}

.input-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon {
  border: 2px solid $brand-border;
  border-radius: 5px;
  color: #43484d;
  font-size: 14px;
  height: 50px;
  padding: 15px;
  width: 100%;
  &:focus {
    border-color: $brand-primary !important;
  }
}

input[type="checkbox"], input[type="radio"] {
  margin-right: 10px !important;
}

.btn {
  border: none;
  padding: 8px 25px;
  border-radius: 2px;
  i {
    padding-right: 10px;
  }
}

.btn-lg {
  padding: 13px 25px;
  border-radius: 3px;
  font-size: 16px;
}

.btn-fixed-top-left {
  position: fixed;
  left: 52px;
  top: 66px;
  z-index: 99;
}

.btn-success {
  background-color: #7dc855;
  border: medium none;
  color: #fff;
  &:hover {
    background-color: #7dc855;
    color: #fff;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
  }
}

.btn-primary {
  background-color: $brand-primary;
  border: medium none;
  color: #fff;
  font-weight: 300;
  &:hover {
    background-color: $brand-primary;
    color: #fff;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
  }
}

.btn-default {
  color: #03a9f4;
  background: transparent;
  border: 1px solid $brand-primary;
  &:hover {
    background: #03a9f4;
    border: 1px solid #03a9f4;
    color: #fff;
  }
}

.btn-big {
  padding: 15px 40px !important;;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400 !important;
  &:hover {
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
  }
  @include tablet-and-phone {
    font-size: 14px;
  }
}

.btn-img {
  img {
    &:hover {
      transition: box-shadow 500ms ease-out 0.05s;
      background: transparent;
      box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.26);
    }
  }
}

.btn-line {
  display: inline-block;
  color: #fff;
  padding: 10px 22px;
  line-height: 1;
  border: 1px solid #fff;
  border-radius: 0;
  text-align: center;
  font-weight: 100;
  margin: 4px;
  -webkit-transition: all 400ms;
  transition: all 400ms;
  &:hover {
    transition: box-shadow 500ms ease-out 0.05s;
    opacity: 0.8;
    color: $white;
  }
}

.btn-fab {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  text-align: center;
  border-radius: 50%;
  margin: 0 auto;
  &:hover {
    text-decoration: none;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 0.8;
  }
  i {
    font-size: 24px;
    vertical-align: middle;
  }
  &.btn-fab-md {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

.paper-nav-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-decoration: none;
  &.active i, &.dl-active i {
    &::before, &::after {
      background: $brand-primary;
    }
  }
  &:hover, &:focus, &:active {
    outline: none;
    border-bottom: none !important;
    opacity: 0.8;
  }
  i {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 2px;
    color: $brand-primary;
    font: bold 14px/.4 Helvetica;
    text-transform: uppercase;
    text-indent: -55px;
    background: $brand-primary;
    transition: all .2s ease-out;
    &::before, &::after {
      content: '';
      width: 25px;
      height: 2px;
      background: $brand-primary;
      position: absolute;
      left: 0;
      transition: all .2s ease-out;
    }
  }
  &.paper-nav-white {
    > i {
      color: $white;
      background: $white;
      &::before, &::after {
        background: $white;
      }
    }
  }
}

.paper-nav-toggle i::before {
  top: -7px;
}

.paper-nav-toggle i::after {
  bottom: -7px;
}

.paper-nav-toggle:hover i::before {
  top: -10px;
}

.paper-nav-toggle:hover i::after {
  bottom: -10px;
}

.paper-nav-toggle.active i, .paper-nav-toggle.dl-active i {
  background: transparent;

}

.paper-nav-toggle.active i::before, .paper-nav-toggle.dl-active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.paper-nav-toggle.active i::after, .paper-nav-toggle.dl-active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.paper-nav-toggle {
  position: absolute;
  right: 0;
  top: 10px;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  display: block;
  // background: #f86942;
  height: 44px;
  width: 44px;
  z-index: 2001;
  border-bottom: none !important;
  text-align: center;
  @media screen and (max-width: $screen-sm) {
    display: block;
  }
  @include tablet-and-phone {
    position: absolute;
  }
  .left {
    left: 15px;
  }
}