.counter-box {
  .icon {
    font-size: 48px;
    height: 60px;
    display: block;
  }
  .sc-counter {
    font-size: 24px;
  }
  .counter-title {
    font-size: 16px;
  }
}
